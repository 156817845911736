<script>
import AxiosService from "../../axiosServices/AxiosService";

export default {
  name: "Deliveries",
  created() {
    this.tempItem = { ...this.modelItem, id_survey: this.survey.id };
    this.data.user.splice(0);
    this.data.user = this.$store.getters.userList;
  },
  mounted() {
    this.currentCompany = this.currentCompanyFromStore;
  },
  computed: {
    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
    reachedUsersRole() {
      let list = [...this.data.user];

      if (this.tempItem.id_ruoli.length) {
        list = list.filter((u) => this.tempItem.id_ruoli.includes(u.id_role));
      } else {
        list = list.filter((u) => u.id_role === 0);
      }

      if (this.tempItem.id_cluster) {
        list = list.filter((u) => u.id_cluster === this.tempItem.id_cluster);
      }

      if (this.tempItem.id_area) {
        list = list.filter((u) => u.id_area === this.tempItem.id_area);
      }

      if (this.tempItem.id_department) {
        list = list.filter(
          (u) => u.id_department === this.tempItem.id_department
        );
      }

      return list;
    },
    reachedUsersTopRole() {
      let list = [...this.data.user];

      if (this.tempItem.id_ruoli_top.length) {
        list = list.filter((u) =>
          this.tempItem.id_ruoli_top.includes(u.id_role)
        );
      } else {
        list = list.filter((u) => u.id_role === 0);
      }

      if (this.tempItem.id_cluster_top) {
        list = list.filter(
          (u) => u.id_cluster === this.tempItem.id_cluster_top
        );
      }

      if (this.tempItem.id_area_top) {
        list = list.filter((u) => u.id_area === this.tempItem.id_area_top);
      }

      if (this.tempItem.id_department_top) {
        list = list.filter(
          (u) => u.id_department === this.tempItem.id_department_top
        );
      }

      return list;
    },
  },
  watch: {
    currentCompanyFromStore() {
      this.currentCompany = this.currentCompanyFromStore;
    },
    currentCompany() {
      this.fetchData();
    },
  },
  props: {
    survey: Object,
  },
  data() {
    return {
      currentCompany: 0,

      loading: {
        roles: false,
        clusters: false,
        areas: false,
        departments: false,
        surveyTypes: false,
        saveItem: false,
        user: false,
        deliveries: false,
        deleteDelivery: false,
      },

      data: {
        roles: [],
        clusters: [],
        areas: [],
        departments: [],
        surveyTypes: [],
        user: [],
        deliveries: [],
      },

      editing: {},

      fields: [
        {
          key: "actions",
          label: "Actions",
        },
        {
          key: "ggDisponibili",
          label: "Available days",
          sortable: true,
        },
        {
          key: "inizio",
          label: "Starting date",
          sortable: true,
        },
        {
          key: "id_ruoli",
          label: "Roles",
        },
        {
          key: "id_ruoli_top",
          label: "Top roles",
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,

      newDeliveryVisible: false,

      filter: null,

      tempItem: {},

      modelItem: {
        id_survey: 0,
        id_cluster: 0,
        id_area: 0,
        id_department: 0,
        id_cluster_top: 0,
        id_area_top: 0,
        id_department_top: 0,
        id_ruoli: [],
        id_ruoli_top: [],
        ggDisponibili: 0,
        inizio: new Date(),
      },
    };
  },
  methods: {
    roleNameFromId(roleId) {
      return this.data.roles.find((r) => r.id === roleId).nome;
    },
    resetData() {
      this.newDeliveryVisible = false;
      this.tempItem = { ...this.modelItem };
    },
    confirmDeleteDelivery(delivery) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this delivery?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) this.deleteItem(delivery);
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },

    async deleteItem(delivery) {
      const service = new AxiosService("Survey/DeleteErogazione");

      this.loading.deleteDelivery = true;

      try {
        await service.delete(delivery.id);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading.deleteDelivery = false;
        this.resetData();
        this.getData(
          "Survey/GetErogazioni/" + this.survey.id,
          "deliveries",
          false
        );
      }
    },
    async saveItem() {
      const service = this.tempItem.id
        ? new AxiosService("Survey/AggiornaErogazione")
        : new AxiosService("Survey/NuovaErogazione");

      this.loading.saveItem = true;

      try {
        this.tempItem.id
          ? await service.update(this.tempItem)
          : await service.create(this.tempItem);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading.saveItem = false;
        this.resetData();
        this.getData(
          "Survey/GetErogazioni/" + this.survey.id,
          "deliveries",
          false
        );
      }
    },

    async fetchData() {
      if (!this.currentCompany) return;
      await Promise.all([
        this.getData("Role", "roles"),
        this.getData("AdvancedTargeting/GetClusters", "clusters"),
        this.getData("AdvancedTargeting/GetAreas", "areas"),
        this.getData("AdvancedTargeting/GetDepartments", "departments"),
        this.getData("Option/SurveyType", "surveyTypes", false),
        this.data.user.length == 0 ? this.getData("User", "user") : false,
        this.getData(
          "Survey/GetErogazioni/" + this.survey.id,
          "deliveries",
          false
        ),
      ]);
    },
    async getData(apiEndpoint, dataKey, filterByCompany = true) {
      if (!this.currentCompany) return;
      this.loading[dataKey] = true;
      this.data[dataKey].splice(0);

      const service = new AxiosService(apiEndpoint);

      try {
        let response = await service.read();

        if (filterByCompany) {
          this.data[dataKey] = response.filter(
            (item) => item.id_company == this.currentCompany
          );
        } else {
          this.data[dataKey] = response;
        }

        if (dataKey == "deliveries") {
          this.totalRows = this.data[dataKey].length;
          for (let d of this.data[dataKey]) {
            this.editing[d.id] = false;
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading[dataKey] = false;
      }
    },
  },
};
</script>

<template>
  <div class="content-wrapper mt-2 bg-white h-100 py-4">
    <h4>Deliveries</h4>

    <b-card class=" bg-light mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>

        <b-col v-if="!newDeliveryVisible" class="text-lg-right">
          <b-button
            :disabled="Object.values(loading).some((val) => val)"
            @click="newDeliveryVisible = !newDeliveryVisible"
            size="sm"
            class="mb-2"
          >
            <b-icon icon="plus" aria-hidden="true"></b-icon>
            New Delivery
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="newDeliveryVisible" class="bg-light mb-4">
      <b-row class="my-4">
        <b-col cols="12">
          <div
            class="w-100 flex justify-content-between align-items-center mb-4"
          >
            <div class="flex flex-column">
              <h5>Delivery details</h5>
              <div class="flex flex-column">
                <span>Survey type:</span>
                <div
                  class="w-100 flex justify-content-start align-items-center"
                >
                  <b-badge
                    v-for="t in survey.id_tipi"
                    class="mr-2"
                    variant="primary"
                  >
                    {{ data.surveyTypes.filter((s) => s.value == t)[0].text }}
                  </b-badge>
                </div>
              </div>
            </div>
            <b-button
              :disabled="Object.values(loading).some((val) => val)"
              @click="newDeliveryVisible = !newDeliveryVisible"
              size="sm"
              class="mb-2"
            >
              <b-icon icon="x" aria-hidden="true"></b-icon>
              Close
            </b-button>
          </div>
        </b-col>

        <b-col
          v-if="survey.id_tipi.includes(2) || survey.id_tipi.includes(3)"
          cols="12"
          class="mb-4"
        >
          <b-card class="bg-white">
            <b-row>
              <b-col cols="6" class="mb-4">
                <h4>Ruolo Top</h4>
              </b-col>

              <b-col cols="6" class="mb-4">
                <span
                  :id="
                    reachedUsersTopRole.length
                      ? 'reachedUsersTopRole'
                      : 'noReachedTopRole'
                  "
                >
                  <b-icon icon="people-fill"></b-icon>
                  Reached users:
                  {{ reachedUsersTopRole.length || "No reached users" }}
                </span>

                <b-tooltip
                  :target="
                    reachedUsersTopRole.length
                      ? 'reachedUsersTopRole'
                      : 'noReachedTopRole'
                  "
                  triggers="hover"
                >
                  <div class="w-100 px-3">
                    <b-row v-for="u in reachedUsersTopRole" :key="u.id">
                      <b-col cols="12">
                        <span class="mr-2">{{ u.nome }} {{ u.cognome }} </span>
                      </b-col>
                    </b-row>
                  </div>
                </b-tooltip>
              </b-col>

              <b-col cols="12" class="mb-4">
                <label for="role">Role</label>
                <TagsInput
                  :disabled="!!tempItem.id"
                  v-model="tempItem.id_ruoli_top"
                  :tagsList="data.roles"
                  text-field="nome"
                  value-field="id"
                />
              </b-col>

              <b-col
                v-if="tempItem.id_ruoli_top.length"
                cols="12"
                md="4"
                class="mb-4"
              >
                <div
                  class="w-100 flex justify-content-between align-items-center"
                >
                  <label for="cluster_top">Cluster</label>

                  <b-button
                    :disabled="!!tempItem.id"
                    variant="light"
                    size="sm"
                    @click="tempItem.id_cluster_top = null"
                  >
                    <b-icon icon="x"></b-icon>
                  </b-button>
                </div>
                <b-form-select
                  :disabled="!!tempItem.id"
                  id="cluster_top"
                  v-model="tempItem.id_cluster_top"
                  :options="
                    data.clusters.map((c) => ({ text: c.nome, value: c.id }))
                  "
                ></b-form-select>
              </b-col>

              <b-col
                v-if="tempItem.id_ruoli_top.length"
                cols="12"
                md="4"
                class="mb-4"
              >
                <div
                  class="w-100 flex justify-content-between align-items-center"
                >
                  <label for="area_top">Area</label>

                  <b-button
                    :disabled="!!tempItem.id"
                    variant="light"
                    size="sm"
                    @click="tempItem.id_area_top = null"
                    ><b-icon icon="x"></b-icon
                  ></b-button>
                </div>
                <b-form-select
                  :disabled="!!tempItem.id"
                  id="area_top"
                  v-model="tempItem.id_area_top"
                  :options="
                    data.areas.map((a) => ({ text: a.nome, value: a.id }))
                  "
                ></b-form-select>
              </b-col>

              <b-col
                v-if="tempItem.id_ruoli_top.length"
                cols="12"
                md="4"
                class="mb-4"
              >
                <div
                  class="w-100 flex justify-content-between align-items-center"
                >
                  <label for="department_top">Department</label>

                  <b-button
                    :disabled="!!tempItem.id"
                    variant="light"
                    size="sm"
                    @click="tempItem.id_department_top = null"
                    ><b-icon icon="x"></b-icon
                  ></b-button>
                </div>
                <b-form-select
                  :disabled="!!tempItem.id"
                  id="department_top"
                  v-model="tempItem.id_department_top"
                  :options="
                    data.departments.map((d) => ({ text: d.nome, value: d.id }))
                  "
                ></b-form-select>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" class="mb-4">
          <b-card class="bg-white">
            <b-row>
              <b-col cols="6" class="mb-4">
                <h4>Ruolo</h4>
              </b-col>

              <b-col cols="6" class="mb-4">
                <span
                  :id="
                    reachedUsersTopRole.length
                      ? 'reachedUsersRole'
                      : 'noReachedRole'
                  "
                  v-if="reachedUsersRole.length"
                  tabindex="0"
                  class="d-inline-block"
                >
                  <b-icon icon="people-fill"></b-icon>
                  Reached users: {{ reachedUsersRole.length }}
                </span>
                <span v-else class="text-danger">
                  <b-icon icon="people-fill"></b-icon>
                  No reached users
                </span>
                <b-tooltip
                  :target="
                    reachedUsersRole.length
                      ? 'reachedUsersRole'
                      : 'noReachedRole'
                  "
                  triggers="hover"
                >
                  <div class="w-100 px-3">
                    <b-row v-for="u in reachedUsersRole" :key="u.id">
                      <b-col cols="12">
                        <span class="mr-2">{{ u.nome }} {{ u.cognome }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </b-tooltip>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mb-4">
                <label for="role">Role</label>
                <TagsInput
                  :disabled="!!tempItem.id"
                  v-model="tempItem.id_ruoli"
                  :tagsList="data.roles"
                  text-field="nome"
                  value-field="id"
                />
              </b-col>

              <b-col
                v-if="tempItem.id_ruoli.length"
                cols="12"
                md="4"
                class="mb-4"
              >
                <div
                  class="w-100 flex justify-content-between align-items-center"
                >
                  <label for="cluster">Cluster</label>

                  <b-button
                    :disabled="!!tempItem.id"
                    variant="light"
                    size="sm"
                    @click="tempItem.id_cluster = null"
                    ><b-icon icon="x"></b-icon
                  ></b-button>
                </div>
                <b-form-select
                  :disabled="!!tempItem.id"
                  id="cluster"
                  v-model="tempItem.id_cluster"
                  :options="
                    data.clusters.map((c) => ({ text: c.nome, value: c.id }))
                  "
                ></b-form-select>
              </b-col>

              <b-col
                v-if="tempItem.id_ruoli.length"
                cols="12"
                md="4"
                class="mb-4"
              >
                <div
                  class="w-100 flex justify-content-between align-items-center"
                >
                  <label for="area">Area</label>

                  <b-button
                    :disabled="!!tempItem.id"
                    variant="light"
                    size="sm"
                    @click="tempItem.id_area = null"
                    ><b-icon icon="x"></b-icon
                  ></b-button>
                </div>
                <b-form-select
                  :disabled="!!tempItem.id"
                  id="area"
                  v-model="tempItem.id_area"
                  :options="
                    data.areas.map((a) => ({ text: a.nome, value: a.id }))
                  "
                ></b-form-select>
              </b-col>

              <b-col
                v-if="tempItem.id_ruoli.length"
                cols="12"
                md="4"
                class="mb-4"
              >
                <div
                  class="w-100 flex justify-content-between align-items-center"
                >
                  <label for="department">Department</label>

                  <b-button
                    :disabled="!!tempItem.id"
                    variant="light"
                    size="sm"
                    @click="tempItem.id_department = null"
                    ><b-icon icon="x"></b-icon
                  ></b-button>
                </div>
                <b-form-select
                  :disabled="!!tempItem.id"
                  id="department"
                  v-model="tempItem.id_department"
                  :options="
                    data.departments.map((d) => ({ text: d.nome, value: d.id }))
                  "
                ></b-form-select>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" md="6" class="mb-2">
          <label for="inizio">Starting date</label>
          <b-form-input id="inizio" type="date" />
        </b-col>

        <b-col cols="12" md="6">
          <label for="ggDisponibili">Available days</label>
          <b-form-input
            id="ggDisponibili"
            type="number"
            v-model="tempItem.ggDisponibili"
          />
        </b-col>

        <b-col cols="12">
          <div class="w-100 flex justify-content-end align-items-center">
            <b-button
              squared
              variant="primary"
              size="lg"
              class="big-button my-5"
              @click="saveItem"
              :loading="loading.saveItem"
              :disabled="loading.saveItem"
            >
              {{ loading.saveItem ? "Saving..." : "Save" }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="bg-light mb-4">
      <b-row v-if="Object.values(loading).some((val) => val)">
        <b-col cols="12" class="text-center">
          <b-spinner small label="Loading..."></b-spinner>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-table
          :items="data.deliveries"
          :fields="fields"
          :filter="filter"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(actions)="row">
            <b-icon
              class="pointer mr-2"
              icon="trash"
              @click="confirmDeleteDelivery(row.item)"
            ></b-icon>

            <b-icon
              class="pointer"
              icon="gear-fill"
              @click="
                tempItem = row.item;
                newDeliveryVisible = true;
              "
            ></b-icon>
          </template>

          <template #cell(ggDisponibili)="row">
            <span>{{ row.item.ggDisponibili || 0 }}</span>
          </template>

          <template #cell(inizio)="row">
            {{
              new Date(row.item.inizio).toLocaleDateString("it", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            }}
          </template>

          <template #cell(id_ruoli)="row">
            <div class="d-flex flex-wrap">
              <small
                v-for="ruolo in row.item.id_ruoli"
                :key="ruolo"
                class="mr-1 mb-1 px-4 py-1 bg-gray rounded"
                >{{ roleNameFromId(ruolo) }}</small
              >
            </div>
          </template>

          <template #cell(id_ruoli_top)="row">
            <div class="d-flex flex-wrap">
              <small
                v-for="ruolo in row.item.id_ruoli_top"
                :key="ruolo"
                class="mr-1 mb-1 px-4 py-1 bg-gray  rounded"
                >{{ roleNameFromId(ruolo) }}</small
              >
            </div>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="w-100 mt-4 mb-1"
        ></b-pagination>
      </b-row>
    </b-card>
  </div>
</template>
